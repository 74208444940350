import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import Slices from 'components/Slices';

import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './post.module.css';

export const query = graphql`
  query PostQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      slug
      title
      items {
        ... on ContentfulGallery {
          id
          title
          url
          images {
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1200
              placeholder: BLURRED
            )
          }
          internal {
            type
          }
        }
        ... on ContentfulImage {
          id
          title
          url
          media {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1200
              placeholder: BLURRED
            )
          }
          internal {
            type
          }
        }
        ... on ContentfulText {
          id
          title
          text {
            raw
          }
          internal {
            type
          }
        }
        ... on ContentfulVideo {
          id
          title
          url
          cloudinaryMedia {
            id
            public_id
          }
          internal {
            type
          }
        }
      }
    }
  }
`;

export default function Post({ data, location }) {
  const { siteUrl } = useSiteMetadata();
  const post = get(data, 'contentfulPost', false);

  if (!post) return null;

  const canonical = `${siteUrl}${location.pathname}`;

  return (
    <Layout location={location}>
      <GatsbySeo
        title={post.title}
        canonical={canonical}
        openGraph={{
          title: post.title,
          url: canonical,
        }}
      />
      <h1 className={styles.title}>{post.title}</h1>
      <Slices items={post.items || []} />
    </Layout>
  );
}
